import React, { useState, useEffect } from "react";
import "./modalCustom.css";
import AppIcon from "../icons/Icon";

function ModalCustom(props) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <div className={`modalCustom ${visible ? "show" : ""}`}>
      <div className="modalCustom-content">
        <div className="close" onClick={() => props.setVisible(false)}>
          <AppIcon name="x" type="feather" />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default ModalCustom;
