import React, { createContext, useReducer } from "react";
import {
  userDetailReducer,
  userDetailsState,
  globalLoaderReducer,
  globalLoaderState,
  onBoardingReducer,
  onBoardingState,
  initReducer,
  initState,
  balanceTriggerState,
  setBalanceTriggerReducer,
  showRedirectReducer,
  showRedirectState,
  userWalletReducer,
  userWalletState,
  gameDataState,
  gameDataReducer,
  paymentCompleteState,
  paymentCompleteReducer,
  gameInstanceReducer,
  gameInstancesState,
} from "./genericReducer";

const reduceReducers = (...reducers) => (prevState, value, ...args) =>
  reducers.reduce(
    (newState, reducer) => reducer(newState, value, ...args),
    prevState
  );

const combinedReducers = reduceReducers(
  userDetailReducer,
  globalLoaderReducer,
  onBoardingReducer,
  initReducer,
  setBalanceTriggerReducer,
  showRedirectReducer,
  userWalletReducer,
  gameDataReducer,
  paymentCompleteReducer,
  gameInstanceReducer
);

const initialState = {
  ...userDetailsState,
  ...globalLoaderState,
  ...onBoardingState,
  ...initState,
  ...balanceTriggerState,
  ...showRedirectState,
  ...userWalletState,
  ...gameDataState,
  ...paymentCompleteState,
  ...gameInstancesState,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combinedReducers, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
