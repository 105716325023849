import React from "react";
import logo from "../../assets/logo.png";
import blackBallons from "../../assets/blackFriday/blackBallons.png";
import blackLogo from "../../assets/blackFriday/blackLogo.png";
import blackCash from "../../assets/blackFriday/blackCash.png";
import "./blackFriday.css";

function BlackFriday(props) {
  return (
    <div className="blackfriday">
      <div className="head">
        <div className="desktop">
          {" "}
          <img src={logo} />
        </div>
        <button onClick={props.onClose}>X</button>
      </div>
      <img src={blackBallons} className="blackBallons" />
      <div className="contentMain">
        <img className="blackLogo" src={blackLogo} />
        <div className="textFormation">
          <div className="main">WIN</div>
          <div className="others">
            <div>UP</div>
            <div>TO</div>
          </div>
        </div>
        <img className="blackCash" src={blackCash} />
        <div className="context">EVERY HOUR, EVERY FRIDAY</div>
        <div className="textBox">Text "RAF" to 33088</div>

        <button className="playButt">PLAY NOW!</button>
        <div className="tc">*Draw valid from 7AM to 7PM every Friday</div>

        <div className="mobile">
          <img src={logo} className="logo" />
        </div>
      </div>
    </div>
  );
}

export default BlackFriday;
