import React, { useState, useEffect, useContext } from "react";
import AppIcon from "../icons/Icon";
import { axiosHandler } from "../../utils/axiosHandler";
import { GET_WALLET_URL } from "../../utils/urls";
import {
  formatCurrency,
  getClientId,
  getToken,
  numberWithCommas,
} from "../../utils/helper";
import { store } from "../../stateManagement/store";
import {
  setBalanceTrigger,
  setUserWallet,
} from "../../stateManagement/actions";
import _ from "lodash";

function BalanceController(props) {
  const [canViewPrice, setCanViewPrice] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [activeAccount, setActiveAccount] = useState(null);
  const {
    state: { balanceTrigger },
    dispatch,
  } = useContext(store);

  useEffect(() => {
    if (balanceTrigger) {
      getBalance();
      dispatch({ type: setBalanceTrigger, payload: false });
    }
  }, [balanceTrigger]);

  const getBalance = () => {
    axiosHandler({
      method: "get",
      url:
        GET_WALLET_URL +
        `?customerId=${props.userDetails.userId}&projection=walletWithDetails`,
      clientID: getClientId(),
      token: getToken(),
    }).then((res) => {
      try {
        let sum = 0;
        let newData = _.get(res.data, "_embedded.wallets", []).map((item) => {
          sum += item.balance;
          return {
            name: item.accountType.name,
            balance: item.balance,
          };
        });
        setActiveAccount(sum);
        dispatch({
          type: setUserWallet,
          payload: {
            data: newData,
            sum,
            id:
              _.get(res.data, "_embedded.wallets", [])[0] &&
              _.get(res.data, "_embedded.wallets", [])[0].id,
          },
        });
        setFetching(false);
      } catch (e) {}
    });
  };

  return (
    <li className="bal">
      <div className=" inner">
        <div>Bal.</div>&nbsp; &nbsp;
        <AppIcon
          name={canViewPrice ? "eyeOff" : "eye"}
          type="feather"
          onClick={() => setCanViewPrice(!canViewPrice)}
        />
      </div>
      {canViewPrice && !fetching && (
        <div className="money">
          {activeAccount
            ? `${formatCurrency((parseFloat(activeAccount) / 100).toString())}`
            : "0.00"}
        </div>
      )}
    </li>
  );
}

export default BalanceController;
