import React, { useContext, useEffect, useState } from "react";
import "./mainlayout.css";
import {
  addClass,
  getClientId,
  getToken,
  hasClass,
  removeClass,
} from "../../utils/helper";
import { store } from "../../stateManagement/store";
import logo from "../../assets/logo.png";
import { secondaryColor, USERTOKEN } from "../../utils/data";
import { Spinner } from "../spinner/Spinner";

import { Link } from "react-router-dom";
import AppIcon from "../icons/Icon";
import {
  setOnBoarding,
  setUserDetails,
  setGameInstances,
} from "../../stateManagement/actions";
import { axiosHandler } from "../../utils/axiosHandler";
import {
  USER_ME_URL,
  GAME_INSTANCE_URL,
  GAME_APPS_URL,
  GAME_TERMINAL_URL,
} from "../../utils/urls";
import NavBarState from "./navbarState";
import Footer from "./Footer";
import _ from "lodash";

export const checkIfTokenIsValid = (_) => {
  return axiosHandler({
    method: "get",
    url: USER_ME_URL,
    token: getToken(),
    clientID: getClientId(),
  });
};

export const getActiveGameInstances = (_) => {
  return axiosHandler({
    method: "get",
    url: GAME_INSTANCE_URL + "?isActive=true",
    token: getToken(),
    clientID: getClientId(),
  });
};

export const getGameApps = (_) => {
  return axiosHandler({
    method: "get",
    url: GAME_APPS_URL,
    token: getToken(),
    clientID: getClientId(),
  });
};

export const getGameTerminals = (_) => {
  return axiosHandler({
    method: "get",
    url: GAME_TERMINAL_URL,
    token: getToken(),
    clientID: getClientId(),
  });
};

function MainLayout(props) {
  const { dispatch } = useContext(store);
  const [loading, setLoading] = useState(false);

  const toggleSlider = () => {
    const el = document.getElementById("sidebar");
    if (hasClass(el, "open")) {
      removeClass(el, "open");
    } else {
      addClass(el, "open");
    }
  };

  const removeSlider = () => {
    const el = document.getElementById("sidebar");
    if (hasClass(el, "open")) {
      removeClass(el, "open");
    }
  };

  useEffect(() => {
    // verify if user is logged in...
    checkIfTokenIsValid().then(
      (res) => {
        dispatch({ type: setUserDetails, payload: res.data.data });
      },
      (_) => {
        localStorage.removeItem(USERTOKEN);
      }
    );

    getActiveGameInstances().then(
      (res) => {
        dispatch({
          type: setGameInstances,
          payload: _.get(res, "data._embedded.gameInstances", []),
        });
      },
      (_) => {}
    );
  }, []);

  useEffect(() => {
    removeSlider();
  }, [window.location.href]);

  const routeToLogin = () => {
    localStorage.removeItem(USERTOKEN);
    window.location.reload();
  };

  const onBoardUser = (e, signIn = false) => {
    e.preventDefault();
    dispatch({
      type: setOnBoarding,
      payload: {
        status: true,
        callBack: null,
        signIn: signIn,
      },
    });
  };

  if (loading) {
    return <Spinner size={15} color={secondaryColor} />;
  }

  return (
    <div className="bg-container home-container">
      <div className="inner-container">
        <div className="mobile ">
          <div className="sidebar" id="sidebar">
            <div className="overlay" onClick={toggleSlider} />

            <div className="contain-side">
              <div className="close" onClick={toggleSlider}>
                <AppIcon name="x" type="feather" />
              </div>
              <div className="mobile">
                <NavBarState
                  onBoardUser={onBoardUser}
                  toggleSlider={toggleSlider}
                  logout={routeToLogin}
                  mobile
                />
              </div>
            </div>
          </div>
        </div>
        <div className="heading">
          <div className="brand">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="desktop">
            <NavBarState
              onBoardUser={onBoardUser}
              toggleSlider={toggleSlider}
              logout={routeToLogin}
            />
          </div>
          <div className="navright mobile">
            <div className="menu-bar" onClick={toggleSlider}>
              <AppIcon name="menu" type="feather" />
            </div>
          </div>
        </div>
        <div className="children"> {props.children}</div>
        <Footer />
        <div className="mobile">
          <br />
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
