import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainLayout from "./components/mainLayout/mainLayout";
import GlobalLoader from "./components/GlobalLoader/globalLoader";
import Logout from "./components/logout/logout";
import OnBoarding from "./pages/onBoarding/onBoarding";
import AuthLayout from "./components/mainLayout/authLayout";
import Redirector from "./pages/general/redirector";
import { Spinner } from "./components/spinner/Spinner";
import { primaryColor } from "./utils/data";
import ResultsHistory from "./pages/general/results";
import CashFlowHistory from "./pages/general/cashFlow";
import RecentWinners from "./pages/general/recentWinner";
import QuickTests from "./pages/quickTests/quicktest";
import DaysOf from "./pages/quickTests/daysOf";

const Home = lazy(() => import("./pages/home/home"));
const Game = lazy(() => import("./pages/game/game"));
const Contact = lazy(() => import("./pages/contact/contact"));
const Profile = lazy(() => import("./pages/profile/profile"));
const FundWallet = lazy(() => import("./pages/general/fundWallet"));
const CashOut = lazy(() => import("./pages/general/cashout"));
const Transfer = lazy(() => import("./pages/general/transfer"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Raffle = lazy(() => import("./pages/game/raffle"));
const GameHistory = lazy(() => import("./pages/general/gameHistory"));
const ResultHistory = lazy(() => import("./pages/general/results"));
const StaticPage = lazy(() => import("./pages/general/staticPage"));
const TransactionHistory = lazy(() =>
  import("./pages/general/transactionHistory")
);

const Loader = () => <Spinner color={primaryColor} />;

const RouterMain = (props) => {
  return (
    <>
      <GlobalLoader />
      <OnBoarding />
      <Redirector />
      <BrowserRouter>
        {/* <DaysOf /> */}
        <Switch>
          <Route path="/logout" exact component={Logout} />
          <Route
            path="/"
            component={(props) => (
              <MainLayout {...props}>
                <Route
                  path="/"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <Home {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/game"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <Game {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/game/raffle"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <Raffle {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/contact"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <Contact {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/profile"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <Profile {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/results"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <ResultsHistory {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/recent-winners"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <RecentWinners {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/quick-test"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <QuickTests {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/transaction-history"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <TransactionHistory {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/cashflow"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <CashFlowHistory {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/game-history"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <GameHistory {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/fund-wallet"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <FundWallet {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/cashout"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <CashOut {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/transfer"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <Transfer {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/dashboard"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <AuthLayout {...props}>
                        <Dashboard {...props} />
                      </AuthLayout>
                    </Suspense>
                  )}
                />
                <Route
                  path="/:label"
                  exact
                  component={(props) => (
                    <Suspense fallback={<Loader />}>
                      <StaticPage {...props} />
                    </Suspense>
                  )}
                />
              </MainLayout>
            )}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default RouterMain;
