import React, { useState, useEffect, useContext } from "react";
import { store } from "../../stateManagement/store";

function Redirector(props) {
  const [canShowInfo, setCanShowInfo] = useState(false);
  const {
    state: { showRedirect }
  } = useContext(store);

  useEffect(() => {
    if (showRedirect.status) {
      setCanShowInfo(true);
    } else {
      setCanShowInfo(false);
    }
  }, [showRedirect]);

  if (!canShowInfo) return <></>;

  setTimeout(() => {
    window.location.href = showRedirect.data.link;
  }, 2000);

  return (
    <div className="redirection">
      <h3>Redirecting...</h3>
      <p>{showRedirect.data.info}</p>
      <a href={showRedirect.data.link}>{showRedirect.data.link}</a>
    </div>
  );
}

export default Redirector;
