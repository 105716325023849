import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import countPrice from "../../assets/blackFriday/countPrice.png";
import countRibbon from "../../assets/blackFriday/countRibbon.png";
import moment from "moment";

import "./countDown.css";

function CountDown(props) {
  const [timer, setTimer] = useState({});
  const [starter, _] = useState(moment().add(1, "hours"));

  useEffect(() => {
    getDuration();
    setInterval(() => getDuration(), 1000);
  }, []);

  const getDuration = () => {
    var diffTime = starter.diff(moment());
    var duration = moment.duration(diffTime, "milliseconds");

    setTimer({
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    });
  };

  return (
    <div className="countDown">
      <img src={countRibbon} className="ribbon" />
      <div className="desktop">
        <img src={countPrice} className="price" />
      </div>
      <div className="head">
        <div className="desktop">
          <img src={logo} />
        </div>
        <button onClick={props.onClose}>X</button>
      </div>
      <div className="contentMain">
        <div className="mobile">
          <img src={countPrice} className="price" />
        </div>
        <div className="context">COUNT BEGINS</div>
        <div className="timer">
          {timer.hours}:{timer.minutes}:{timer.seconds}
        </div>
        <div className="winners">24 winners every hour</div>
        <div className="textBox">*Starting Noon Eve to Noon Xmas</div>
        <div className="textContent">Text "RAF" to 33088</div>

        <button className="playButt">PLAY NOW!</button>
        <div className="mobile">
          <img src={logo} className="logo" />
        </div>
      </div>
    </div>
  );
}

export default CountDown;
