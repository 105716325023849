import React, { useEffect, useState } from "react";
import { store } from "../../stateManagement/store";
import { Notification } from "../notification/Notification";
import { checkIfTokenIsValid } from "./mainLayout";
import { Spinner } from "../spinner/Spinner";
import { primaryColor } from "../../utils/data";

function AuthLayout({ children, history }) {
  const [canAccess, setCanAccess] = useState(false);

  useEffect(() => {
    checkIfTokenIsValid().then(
      _ => {
        setCanAccess(true);
      },
      _ => {
        history.push("/");
      }
    );
  }, []);

  if (!canAccess)
    return (
      <center>
        <Spinner color={primaryColor} />
      </center>
    );

  return <>{children}</>;
}

export default AuthLayout;
