import React from 'react';

function MobileShare(props) {
    return (
        <div className="mobile">
            <p/>
            <div className="sharer">
                <p>share the good news with your friend</p>
                <button>Share</button>
                <ul className="social-link">
                    <li className="facebook"><img src="./assets/facebook.svg" alt=""/></li>
                    <li><img src="./assets/twitter.svg" alt=""/></li>
                    <li><img src="./assets/whatsapp.svg" alt=""/></li>
                    <li><img src="./assets/mail.svg" alt=""/></li>
                </ul>
            </div>
            <br/><br/>
        </div>
    );
}

export default MobileShare;