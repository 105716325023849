import { getCloudinaryName } from "./helper";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const USER_BASE_URL = BASE_URL + "users/v1/";
export const GAME_BASE_URL = process.env.REACT_APP_GAME_BASE_URL;
export const BILLING_URL = BASE_URL + "billings";
export const PAYMENT_URL = BASE_URL + "payments/v1/";
export const THIRD_PARTY_URL = BASE_URL + "3ps/v1/";
export const THIRD_PARTY_URL2 = BASE_URL + "3ps/v2/";

export const LOGIN_URL = USER_BASE_URL + "auths/login";
export const USER_ME_URL = USER_BASE_URL + "auths/me";
export const USER_URL = USER_BASE_URL + "users";
export const USER_CHECK_URL = USER_URL + "/check?phoneNumber=";
export const VERIFY_PHONE_URL = USER_BASE_URL + "auths/verify";
export const VERIFY_PHONE_CODE_URL = USER_BASE_URL + "auths/verify/code";
export const CHANGE_PASSWORD_URL = USER_BASE_URL + "passwords/change";
export const SEND_RESET_PASSWORD_URL = USER_BASE_URL + "passwords/reset";

export const CLOUDINARY_URL = () =>
  `https://api.cloudinary.com/v1_1/${getCloudinaryName()}/upload`;

// billing service urls
export const GET_WALLET_URL = BILLING_URL + "/wallets";
export const GET_TRANSACTION_ANALYTICS =
  BILLING_URL + "/analytics/wallet-transaction/value";
export const CHARGE_BILL_URL = BILLING_URL + "/charge";
export const BILLING_ACCOUNT_URL = BILLING_URL + "/accounts";
export const WALLET_TRANSACTIONS_URL = BILLING_URL + "/wallet-transactions";
export const TRANSACTIONS_URL = BILLING_URL + "/transactions";
export const PROVIDERS_URL = BILLING_URL + "/payment-providers";
export const WITHDRAWAL_URL = BILLING_URL + "/withdraw";

// payment service urls
export const VERIFY_PAYMENT_URL = (provider) =>
  PAYMENT_URL + `${provider}/verify/`;
export const INITIALIZE_PAYMENT_URL = (provider) =>
  PAYMENT_URL + `${provider}/initialize`;
export const PAYMENT_CARD_URLS = PAYMENT_URL + "cards/";
export const PAYMENT_CHARGE_AUTH_URLS = PAYMENT_URL + "paystack/charge";

// game service urls
export const GAME_PLAY_URL = GAME_BASE_URL + "play/";
export const GAME_BUNDLE_PLAY_URL = GAME_BASE_URL + "play/bundle/";
export const GAME_TRANSACTIONS_URL = GAME_BASE_URL + "gameTransactions";
export const GAME_DRAW_URL = GAME_BASE_URL + "draws";
export const GAME_INSTANCE_URL = GAME_BASE_URL + "gameInstances";
export const GAME_APPS_URL = GAME_BASE_URL + "apps";
export const GAME_TERMINAL_URL = GAME_BASE_URL + "terminals";
export const GAME_TRANSACTION_ANALYTICS =
  GAME_BASE_URL + "analytics/game-transactions";

// third party urls
export const TRANSFER_AUTH_URL = THIRD_PARTY_URL + "transferauth/";
export const TRANSFER_AUTH_URL2 = THIRD_PARTY_URL2 + "transferauth/";
export const BANKS_FETCH_URL = THIRD_PARTY_URL + "banks?provider=paystack";
export const FUNDTRANSFER_INITAILIZER_URL = (provider) =>
  THIRD_PARTY_URL + `fundtransfer/${provider}/initialize`;
