import React from "react";
import visaMasterLogo from "../../assets/logo/visaMasterLogo.png";
import payStackLogo from "../../assets/logo/paystack2.png";
import facebook from "../../assets/logo/fb_logo.png";
import twitter from "../../assets/logo/twitter.png";
import instagram from "../../assets/logo/instagram.png";
import youtube from "../../assets/logo/youtube.png";
import plus18 from "../../assets/logo/18plus.png";
import lottery from "../../assets/logo/lottery.png";
import lagosLottery from "../../assets/logo/lagosLottery.png";
import AppIcon from "../icons/Icon";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-grid">
      <div className="footer-item">
        <h3>company</h3>
        <Link to="/how-to-play">
          <li>About Us</li>
        </Link>
        <Link to="">
          <li>Contact Us</li>
        </Link>
        <Link to="/responsible-gaming">
          <li>Responsible Gambling</li>
        </Link>
        <Link to="/terms">
          <li>Terms & Conditions</li>
        </Link>
        <Link to="/privacy-policy">
          <li>Privacy Policy</li>
        </Link>
      </div>

      <div className="footer-item">
        <h3>extra</h3>
        <li>Help</li>
        <li>FAQ</li>
        <li>Bonus</li>
        <li>Deposits</li>
      </div>

      <div className="footer-item">
        <h3>deposit</h3>
        <img src={visaMasterLogo} className="masterLogo" alt="" />
        {/* <img src={payStackLogo} className="paystackLogo" alt="" /> */}
      </div>

      <div className="footer-item">
        <h3>customer support</h3>
        <li>
          <AppIcon name="phone" type="fa" /> 08099033088, 08099233088
        </li>
        <li>
          <AppIcon name="whatsapp" type="fa" /> 08096633088
        </li>
        <li>
          <AppIcon name="ic_mail_outline" type="md" /> support@tylgames.com
        </li>
        <h3>social</h3>
        <div className="social-list">
          <a
            href="https://web.facebook.com/TYLgames/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} className="social-icons" alt="" />
          </a>
          <a
            href="https://twitter.com/TYLgames"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} className="social-icons" alt="" />
          </a>
          <a
            href="https://www.instagram.com/tylgames/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} className="social-icons" alt="" />
          </a>
        </div>
      </div>

      <div className="footer-item license">
        <li>
          <img src={plus18} className="icons" alt="" />
          <p>
            &copy;Transform Your Life 2020. All rights reserved. <br /> Underage
            gambling is forbidden
          </p>
        </li>
        <li>
          <img src={lagosLottery} className="icons" alt="" />
          <p>
            TYL is licensed by the Lagos State Lotteries Board. <br /> license
            No: 001545
          </p>
        </li>
        <li>
          <img src={lottery} className="icons" alt="" />
          <p>
            TYL is licensed by the Nigeria Communication Commission.
            <br />
            VAS/SCN/055/15-R1
          </p>
        </li>
      </div>
    </div>
  );
}

export default Footer;
