import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AppIcon from "../icons/Icon";
import userLogo from "../../assets/user.svg";
import { store } from "../../stateManagement/store";
import BalanceController from "./balanceController";
import { formatCurrency } from "../../utils/helper";
import _ from "lodash";

function NavBarState(props) {
  const [isLogged, setIsLogged] = useState(false);
  const {
    state: { userDetails, userWallet },
  } = useContext(store);

  useEffect(() => {
    if (userDetails.phoneNumber) {
      setIsLogged(true);
    } else {
      if (isLogged) {
        setIsLogged(false);
      }
    }
  }, [userDetails]);

  return (
    <>
      <div className="profile-con">
        {props.mobile && isLogged && userDetails.profile && (
          <>
            <div className="img-con">
              <img
                src={_.get(userDetails, "profile.meta.profile_picture", "")}
                alt=""
              />
            </div>
            <div className="details">
              <small>{`${_.get(userDetails, "profile.firstname", "")} ${_.get(
                userDetails,
                "profile.lastname",
                ""
              )}`}</small>
              {userWallet.sum && <p>{formatCurrency(userWallet.sum / 100)}</p>}
            </div>
          </>
        )}
      </div>

      <div className={props.mobile ? "main-link" : "navright"}>
        {isLogged && (
          <li>
            <Link to="/dashboard">
              <span className="colorWhite">Dashboard</span>&nbsp;
            </Link>
          </li>
        )}
        <li>
          Games &nbsp;
          <AppIcon name="chevronDown" type="feather" />
          <div className="dropdown">
            <a href="/game">
              <div className="drop-item">Shoki</div>
            </a>
            <Link to="/game/raffle">
              <div className="drop-item">Shaffle</div>
            </Link>
          </div>
        </li>
        <li>
          <Link to="/results">
            <div className="drop-item">Results</div>
          </Link>
        </li>
        {/* <li>
          <Link to="/recent-winners">
            <div className="drop-item">Recent Winners</div>
          </Link>
        </li> */}
        {isLogged && (
          <li>
            <Link to="/fund-wallet">
              <div className="drop-item">Deposit</div>
            </Link>
          </li>
        )}
        {isLogged && (
          <li>
            <Link to="/cashout">
              <div className="drop-item">Cashout</div>
            </Link>
          </li>
        )}
        {isLogged && (
          <li>
            Transaction &nbsp;
            <AppIcon name="chevronDown" type="feather" />
            <div className="dropdown">
              <Link to="/transaction-history">
                <div className="drop-item">Wallet</div>
              </Link>
              <Link to="/cashflow">
                <div className="drop-item">Cash Flow</div>
              </Link>
            </div>
          </li>
        )}
        <div className="container-right">
          {isLogged && (
            <li>
              {!props.mobile && (
                <div className="profile">
                  <img src={userLogo} alt="" />
                </div>
              )}
              &nbsp;{userDetails.phoneNumber}&nbsp; &nbsp;
              <AppIcon name="chevronDown" type="feather" />
              <div className="dropdown">
                <Link to="/game-history">
                  <div className="drop-item">Game history</div>
                </Link>
                <Link to="/cashout">
                  <div className="drop-item">Cashout</div>
                </Link>
                <Link to="/profile">
                  <div className="drop-item">Profile</div>
                </Link>
                <div className="drop-item" onClick={props.logout}>
                  Logout
                </div>
              </div>
            </li>
          )}
          {isLogged && !props.mobile && (
            <BalanceController userDetails={userDetails} />
          )}
          {!isLogged && (
            <>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  props.onBoardUser(e);
                  props.toggleSlider();
                }}
                className="register"
              >
                <span>Register</span>
              </li>
              <li
                onClick={(e) => {
                  e.preventDefault();
                  props.onBoardUser(e, true);
                  props.toggleSlider();
                }}
                className="signin"
              >
                Sign in
              </li>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default NavBarState;
