export const setUserDetails = "SET_USER_DETAILS";
export const setGlobalLoader = "SET_GLOBAL_LOADER";
export const setOnBoarding = "SET_ONBOARDING";
export const setBalanceTrigger = "SET_BALANCE_TRIGGER";
export const setUserWallet = "SET_USER_WALLET";
export const showRedirect = "SHOW_REDIRECT";
export const setInitState = "SET_INIT_STATE";
export const setGameDataState = "SET_GAME_DATA_STATE";
export const triggerPaymentComplete = "TRIGGER_PAYMENT_COMPLETE";
export const setGameInstances = "SET_GAME_INSTANCE";
