import React, { useState, useContext, useEffect } from "react";
import "./onboarding.css";
import Input from "../../components/input/Input";
import { Button } from "../../components/button/Button";
import { store } from "../../stateManagement/store";
import { setOnBoarding, setUserDetails } from "../../stateManagement/actions";
import ModalCustom from "../../components/modalCustom/modalCustom";
import {
  errorHandler,
  genericChangeSingle,
  getClientId,
  getRoleID,
} from "../../utils/helper";
import { axiosHandler } from "../../utils/axiosHandler";
import {
  CHANGE_PASSWORD_URL,
  LOGIN_URL,
  SEND_RESET_PASSWORD_URL,
  USER_CHECK_URL,
  USER_URL,
  VERIFY_PHONE_CODE_URL,
  VERIFY_PHONE_URL,
} from "../../utils/urls";
import { Notification } from "../../components/notification/Notification";
import { primaryColor, USERTOKEN } from "../../utils/data";
import { Spinner } from "../../components/spinner/Spinner";
import _ from "lodash";

function OnBoarding(props) {
  const [onBoardState, setOnBoardState] = useState(1);
  const [played, setPlayed] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    dispatch,
    state: { onBoarding },
  } = useContext(store);
  const [inputData, setInputData] = useState({});
  const [tempData, setTempData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (onBoarding.status) {
      if (onBoarding.signIn) setOnBoardState(5);
      else setOnBoardState(1);
      setVisible(true);

      dispatch({
        type: setOnBoarding,
        payload: {
          status: false,
          callBack: onBoarding.callBack,
        },
      });
    }
    setInputData({});
    setLoading(false);
  }, [onBoarding]);

  const submitForm = (e) => {
    e.preventDefault();
    if (onBoardState === 1) {
      setupPhone();
    } else if (onBoardState === 2) {
      setupOtp();
    } else if (onBoardState === 3) {
      setupPassword();
    } else if (onBoardState === 4) {
      setVisible(false);
    } else if (onBoardState === 8) {
      initiateReset();
    } else if (onBoardState === 7) {
      resetPassword();
    } else {
      loginUser();
    }
  };

  const setupPhone = async () => {
    if (inputData.password !== inputData.cpassword) {
      Notification.bubble({
        type: "info",
        content: "passwords don't match",
      });
      return;
    }
    const data = {
      value: inputData.phone,
      type: "phone",
      countryCode: "NG",
      smsProvider: inputData.network,
      createIfNotExist: true,
    };
    // create user first
    setLoading(true);
    let process = await axiosHandler({
      method: "post",
      clientID: getClientId(),
      data: {
        name: "",
        phoneNumber: inputData.phone,
        password: inputData.password,
        roleId: getRoleID(),
        network: inputData.network,
      },
      url: USER_URL,
    }).catch((err) => {});
    setLoading(false);
    if (process) {
      sendVerification(data);
    } else {
      let is_verified = await axiosHandler({
        method: "get",
        url: USER_CHECK_URL + inputData.phone,
        clientID: getClientId(),
      }).catch((err) => console.log(err.response));
      setLoading(false);
      if (!is_verified) {
        sendVerification(data);
      } else {
        let pVerified = _.get(
          is_verified.data,
          "data.phoneNumberVerified",
          false
        );
        let pExist = _.get(is_verified.data, "data.exists", false);
        if (!pExist) {
          setOnBoardState(2);
        } else {
          if (pVerified) {
            setOnBoardState(5);
          } else {
            sendVerification(data);
          }
        }
      }
    }
  };

  const sendVerification = (data, showInfo = false) => {
    axiosHandler({
      method: "post",
      data,
      clientID: getClientId(),
      url: VERIFY_PHONE_URL,
    });
    setLoading(false);
    setOnBoardState(2);
    if (showInfo) {
      Notification.bubble({
        type: "success",
        content: "OTP sent",
      });
    }
  };

  const setupOtp = () => {
    const data = {
      value: inputData.phone,
      type: "phone",
      withToken: true,
      code: inputData.auth_code,
    };
    setLoading(true);
    // verify code
    axiosHandler({
      method: "post",
      data,
      clientID: getClientId(),
      url: VERIFY_PHONE_CODE_URL,
    }).then(
      (res) => {
        Notification.bubble({
          type: "success",
          content: "OTP verified successfully",
        });
        setInputData({
          ...inputData,
          token: res.data.data.token,
        });
        setLoading(false);
        setOnBoarding(false);
      },
      (err) => {
        Notification.bubble({
          type: "error",
          content: errorHandler(err),
        });
        setLoading(false);
      }
    );
  };

  const setupPassword = () => {
    if (inputData.password !== inputData.cpassword) {
      Notification.bubble({
        type: "info",
        content: "passwords don't match",
      });
      return;
    }
    if (inputData.password.length < 6) {
      Notification.bubble({
        type: "info",
        content: "Password must be at least six (6) character",
      });
      return;
    }
    setLoading(true);
    axiosHandler({
      method: "post",
      data: { password: inputData.password },
      token: inputData.token,
      clientID: getClientId(),
      url: CHANGE_PASSWORD_URL,
    }).then(
      (_) => {
        Notification.bubble({
          type: "success",
          content: "Password set successfully",
        });
        loginUser(true);
      },
      (err) => {
        Notification.bubble({
          type: "error",
          content: errorHandler(err),
        });

        setLoading(false);
      }
    );
  };

  const loginUser = (status = false) => {
    if (!loading) {
      setLoading(true);
    }
    const data = {
      phoneNumber: inputData.phone,
      password: inputData.password,
    };
    axiosHandler({
      method: "post",
      data,
      clientID: getClientId(),
      url: LOGIN_URL,
    }).then(
      (res) => {
        localStorage.setItem(
          USERTOKEN,
          JSON.stringify({
            access: res.data.data.token,
            refresh: res.data.data.refresh,
          })
        );
        setLoading(false);
        dispatch({ type: setUserDetails, payload: res.data.data.user });
        if (status) {
          setOnBoardState(4);
        } else {
          setVisible(false);
          if (onBoarding.callBack) {
            onBoarding.callBack(res.data.data.user);
          }
        }
      },
      (err) => {
        Notification.bubble({
          type: "error",
          content: errorHandler(err),
        });

        setLoading(false);
      }
    );
  };

  const initiateReset = () => {
    setLoading(true);
    axiosHandler({
      method: "get",
      clientID: getClientId(),
      url:
        SEND_RESET_PASSWORD_URL +
        `?type=phone&value=${inputData.phone}&countryCode=NG&smsProvider=${inputData.network}`,
    }).then(
      (res) => {
        Notification.bubble({
          type: "success",
          content: `A password reset code has been sent to your phone "${inputData.phone}"`,
        });
        setLoading(false);
        setOnBoardState(7);
        setTempData(inputData);
        setInputData({});
      },
      (err) => {
        Notification.bubble({
          type: "error",
          content: errorHandler(err),
        });
        setLoading(false);
      }
    );
  };

  const resetPassword = () => {
    if (inputData.cpassword !== inputData.password) {
      Notification.bubble({
        type: "info",
        content: "Your passwords do not match",
      });
      return;
    }
    if (inputData.password.length < 6) {
      Notification.bubble({
        type: "info",
        content: "Password must be at least six (6) character",
      });
      return;
    }
    setLoading(true);
    const data = {
      code: inputData.code,
      type: "phone",
      value: tempData.phone,
      password: inputData.password,
    };
    axiosHandler({
      method: "post",
      url: SEND_RESET_PASSWORD_URL,
      clientID: getClientId(),
      data,
    }).then(
      (res) => {
        Notification.bubble({
          type: "success",
          content: res.data.data,
        });
        loginUser();
        setVisible(false);
      },
      (err) => {
        Notification.bubble({
          type: "error",
          content: errorHandler(err),
        });
        setLoading(false);
      }
    );
  };

  const processCond = (check) => {
    if (check === 2) setOnBoardState(5);
    else {
      initiateReset();
    }
  };

  return (
    <div className="onboarding">
      <ModalCustom visible={visible} setVisible={setVisible}>
        <form onSubmit={submitForm}>
          {onBoardState === 1 && (
            <>
              <h3>Create an Account</h3>
              <Input
                name="phone"
                value={inputData.phone || ""}
                type="number"
                required
                placeholder="Phone Number"
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <select
                required
                value={inputData.network}
                name="network"
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              >
                <option value="">Select Network</option>
                <option value="9mobile">9mobile</option>
                <option value="mtn">Mtn</option>
                <option value="generic">Others</option>
              </select>
              <div className="divider" />
              <Input
                placeholder="Password"
                name="password"
                value={inputData.password || ""}
                type="password"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <Input
                placeholder="Repeat password"
                name="cpassword"
                value={inputData.cpassword || ""}
                type="password"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <p />
              <Button type="submit" loading={loading} disabled={loading} block>
                Register
              </Button>
              <div className="link" onClick={() => setOnBoardState(5)}>
                Have an account already?
              </div>
            </>
          )}
          {onBoardState === 2 && (
            <>
              <h3>Verify Account</h3>
              <Input
                name="auth_code"
                value={inputData.auth_code || ""}
                type="number"
                placeholder="Enter OTP"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <div
                className="link"
                onClick={() =>
                  sendVerification(
                    {
                      value: inputData.phone,
                      type: "phone",
                      countryCode: "NG",
                      smsProvider: inputData.network,
                    },
                    true
                  )
                }
              >
                Resend OTP
              </div>
            </>
          )}
          {onBoardState === 8 && (
            <>
              <h3>
                {played ? "I've played on USSD or SMS" : "Forgot Password"}
              </h3>
              <Input
                name="phone"
                placeholder="Phone number"
                value={inputData.phone || ""}
                type="number"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <select
                required
                value={inputData.network}
                name="network"
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              >
                <option value="">--choose network--</option>
                <option value="9mobile">9mobile</option>
                <option value="mtn">Mtn</option>
                <option value="generic">Others</option>
              </select>
            </>
          )}
          {onBoardState === 9 && (
            <>
              <h4>
                We discovered this phone number ({inputData.phone}) exists in
                our system! <br /> Did you recently made use of Tyl from
                SMS/USSD on 33088?{" "}
              </h4>
              <div className="flex">
                <Button
                  loading={loading}
                  disabled={loading}
                  type="button"
                  onClick={() => processCond(1)}
                >
                  YES
                </Button>
                &nbsp;
                <Button
                  disabled={loading}
                  type="button"
                  onClick={() => processCond(2)}
                >
                  NO
                </Button>
              </div>
            </>
          )}
          {onBoardState === 3 && (
            <>
              <h3>Set your password</h3>
              <Input
                placeholder="enter password"
                name="password"
                value={inputData.password || ""}
                type="password"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <br />
              <Input
                placeholder="confirm password"
                name="cpassword"
                value={inputData.cpassword || ""}
                type="password"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
            </>
          )}
          {onBoardState === 4 && (
            <>
              <img src="./assets/check.svg" alt="" />
              <h3>Onboarding process successful</h3>
            </>
          )}
          {onBoardState === 5 && (
            <>
              <h3>Sign In</h3>
              <Input
                placeholder="enter your phone number"
                name="phone"
                value={inputData.phone || ""}
                type="number"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <Input
                placeholder="enter your password"
                name="password"
                value={inputData.password || ""}
                type="password"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <p />
              <Button type="submit" loading={loading} disabled={loading} block>
                Login
              </Button>
              <div
                className="link"
                onClick={() => {
                  setPlayed(false);
                  setOnBoardState(8);
                }}
                style={{ width: "100%", maxWidth: "300px" }}
              >
                Forgot Password
              </div>
              <div className="orControl">
                <div className="item" />
                <div className="key">or</div>
                <div className="item" />
              </div>
              <div className="linkButton" onClick={() => setOnBoardState(1)}>
                Create an Account
              </div>
              <div
                className="linkButton"
                style={{ backgroundColor: "darkorange" }}
                onClick={() => {
                  setPlayed(true);
                  setOnBoardState(8);
                }}
              >
                I've played on USSD or SMS
              </div>
            </>
          )}
          {onBoardState === 6 && <Spinner color={primaryColor} size={100} />}
          {onBoardState === 7 && (
            <>
              <h3>Reset password</h3>
              <small>Went sent a reset code to your phone</small>
              <br />
              <Input
                placeholder="Enter code sent to phone"
                name="code"
                value={inputData.code}
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />

              <Input
                placeholder="New password"
                name="password"
                value={inputData.password}
                type="password"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
              <Input
                placeholder="Repeat password"
                name="cpassword"
                value={inputData.cpassword || ""}
                type="password"
                required
                onChange={(e) =>
                  genericChangeSingle(e, setInputData, inputData)
                }
              />
            </>
          )}
          {onBoardState !== 6 &&
            onBoardState !== 9 &&
            onBoardState !== 5 &&
            onBoardState !== 1 && (
              <>
                <br />
                <Button
                  type="submit"
                  loading={loading}
                  disabled={loading}
                  block
                >
                  {onBoardState === 5
                    ? "Login"
                    : onBoardState === 1
                    ? "Register"
                    : "Continue"}
                </Button>
              </>
            )}
        </form>
      </ModalCustom>
    </div>
  );
}

export default OnBoarding;
