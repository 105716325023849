import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import daysLogo from "../../assets/blackFriday/days12.png";

import "./daysOf.css";
import ModalAdverts from "../../components/modalAdverts/modalCustom";
import { Link, withRouter } from "react-router-dom";
import { randomIntFromInterval } from "../../utils/helper";

function DaysOf(props) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowModal(true), randomIntFromInterval(1, 4) * 3000);
  }, []);

  useEffect(() => {
    if (props.location.pathname === "/game/raffle" && showModal) {
      setShowModal(false);
    } else if (props.location.pathname === "/game" && showModal) {
      setShowModal(false);
    }
  }, [props.location.pathname, showModal]);

  return (
    <ModalAdverts visible={showModal}>
      <div className="daysOff">
        <img src={daysLogo} className="ribbon" />
        <div className="head">
          <button onClick={() => setShowModal(false)}>X</button>
        </div>
        <div className="contentMain">
          <div className="containerMain">
            <div className="context">N 200,000,000</div>
            <div className="basket">Gift Basket</div>
          </div>
          <div className="textBox">
            12 Winners everyday for 12 days <br /> (From 14th - 25th Dec. 2020)
          </div>
          <div className="textContent">Text "RAF" to 33088</div>
          <Link to="/game/raffle">
            <button className="playButt">PLAY NOW!</button>
          </Link>
          <div className="mobile">
            <img src={logo} className="logo" />
          </div>
        </div>
      </div>
    </ModalAdverts>
  );
}

export default withRouter(DaysOf);
