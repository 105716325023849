import React, { useState, useEffect } from "react";
import MobileShare from "./mobileShare";
import "../../styles/dashboard.css";
import { axiosHandler } from "../../utils/axiosHandler";
import {
  errorHandler,
  formatCurrency,
  getClientId,
  getToken,
  numberWithCommas,
} from "../../utils/helper";
import { GAME_DRAW_URL, GAME_TRANSACTIONS_URL } from "../../utils/urls";
import moment from "moment";
import { Button } from "../../components/button/Button";
import { Spinner } from "../../components/spinner/Spinner";
import { primaryColor, secondaryColor } from "../../utils/data";
import ModalCustom from "../../components/modalCustom/modalCustom";
import { Notification } from "../../components/notification/Notification";
import AppIcon from "../../components/icons/Icon";
import RangeFilter from "../../components/rangeFilter/rangeFilter";
import qs from "query-string";

function ResultsHistory(props) {
  const [transactions, setTransactions] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [pageInfo, setPageInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeHistory, setActiveHistory] = useState(null);
  const [queryParams, setQueryParams] = useState({});
  const [search, setSearch] = useState("");
  const [seaching, setSearching] = useState(false);
  const [activeTransactions, setActiveTransactions] = useState([]);
  const [useActive, setUseActive] = useState(false);

  useEffect(() => {
    setFetching(true);
    getTransactions();
  }, [currentPage, queryParams]);

  const getTransactions = async () => {
    let params = qs.stringify(queryParams);
    let extra = `page=${currentPage}&size=10&${params
      .replace("startDate", "startTime")
      .replace("endDate", "endTime")}`;
    const res = await axiosHandler({
      method: "get",
      clientID: getClientId(),
      token: getToken(),
      url:
        GAME_DRAW_URL +
        `?${extra}&sort=endTime,desc&projection=drawWithDetails`,
    });
    if (!res) return;
    setPageInfo(res.data.page);
    if (fetchingMore) {
      setTransactions([...transactions, ...res.data._embedded.draws]);

      setFetchingMore(false);
    } else {
      setTransactions(res.data._embedded.draws);
      setFetching(false);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setSearching(true);
    let res = await axiosHandler({
      method: "get",
      url:
        GAME_TRANSACTIONS_URL +
        `?projection=gameTransactionWithDetails&gameToken=${search}`,
    }).catch((err) => {
      Notification.bubble({
        type: "error",
        content: errorHandler(err),
      });
    });
    if (res) {
      setActiveTransactions(res.data._embedded.gameTransactions);
      setUseActive(true);
      setActiveHistory(true);
      setModalVisible(true);
    }
    setSearching(false);
  };

  const checkDrawTime = (drawTime, endTime) => {
    let tempTime = new Date(endTime).getTime() - new Date().getTime();
    if (tempTime >= 0) {
      if (!drawTime) return "OPEN";
    } else {
      if (!drawTime) return "CLOSED";
    }

    return moment(new Date(drawTime)).format("DD-MM-YYYY hh:mm a");
  };

  return (
    <>
      <div className="dashboard-inner2">
        <div className="heading-dash flex align-center justify-between flex-wrap">
          <h3>Result History</h3>&nbsp;&nbsp;&nbsp;
          {/* <RangeFilter
            startDate={queryParams.startDate}
            endDate={queryParams.endDate}
            onChange={(e) =>
              setQueryParams({
                ...queryParams,
                ...e,
              })
            }
          /> */}
          <form onSubmit={submit} className="flex align-center ">
            <input
              placeholder="search ticket"
              required
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            &nbsp; &nbsp;
            <Button loading={seaching} disabled={seaching} type="submit">
              <span style={{ color: "white" }}>Search</span>
            </Button>
          </form>
        </div>

        <h4 className="mobile">Deposits</h4>
        <div className="trans-table">
          <div className="t-content">
            <table>
              <thead>
                <tr>
                  {/*<th className="desktop" />*/}
                  <th>Draw</th>
                  <th>Draw Time</th>
                  <th></th>
                  {/*<th className="desktop" />*/}
                </tr>
              </thead>
              <tbody>
                {!fetching &&
                  transactions.map((item, id) => {
                    return (
                      <tr key={id}>
                        <td>{`${item.gameInstance.label} - ${moment(
                          new Date(item.endTime)
                        ).format("DD/MM/YYYY")}`}</td>
                        <td>{checkDrawTime(item.drawTime, item.endTime)}</td>
                        <td
                          className="link"
                          onClick={() => {
                            setUseActive(false);
                            setActiveHistory(item);
                            setModalVisible(true);
                          }}
                        >
                          <div className="link">View Ticket</div>
                        </td>
                        {/*<td className="desktop">*/}
                        {/*  <i className="zmdi zmdi-download" />*/}
                        {/*</td>*/}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <br />
          {!fetching && transactions.length < 1 && (
            <center>
              <strong style={{ color: "white" }}>No data available</strong>
            </center>
          )}
        </div>
        {!fetching && (
          <div className="pager">
            <span>
              {pageInfo.number + 1} of {pageInfo.totalPages}
            </span>
            <button
              className={currentPage > 0 ? "" : "disabled"}
              onClick={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1);
                }
              }}
            >
              <AppIcon name="chevronLeft" type="feather" />
            </button>
            <button
              className={
                currentPage + 1 < pageInfo.totalPages ? "" : "disabled"
              }
              onClick={() => {
                if (currentPage + 1 < pageInfo.totalPages) {
                  setCurrentPage(currentPage + 1);
                }
              }}
            >
              <AppIcon name="chevronRight" type="feather" />
            </button>
          </div>
        )}
        {fetching && (
          <center>
            <Spinner color={primaryColor} />
          </center>
        )}
      </div>
      <MobileShare />
      <div className="modal-fixed">
        <ModalCustom visible={modalVisible} setVisible={setModalVisible}>
          {activeHistory && modalVisible && (
            <DrawInfo
              activeDraw={activeHistory}
              transactions={activeTransactions}
              useActive={useActive}
              search={search}
            />
          )}
        </ModalCustom>{" "}
      </div>
    </>
  );
}

const DrawInfo = (props) => {
  const [transactions, setTransactions] = useState(props.transactions);
  const [fetching, setFetching] = useState(true);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [pageInfo, setPageInfo] = useState(null);

  useEffect(() => {
    if (fetchingMore) {
      getTransactions();
    }
  }, [fetchingMore]);

  const loadMore = () => {
    if (canLoadMore) {
      setFetchingMore(true);
    }
  };

  useEffect(() => {
    if (props.useActive) {
      setFetching(false);
      return;
    }
    getTransactions();
  }, []);

  const getTransactions = () => {
    let extra = "";
    if (fetchingMore) {
      extra = `page=${pageInfo.number + 1}`;
    }
    axiosHandler({
      method: "get",
      url:
        GAME_TRANSACTIONS_URL +
        `?projection=gameTransactionWithDetails&draw=${props.activeDraw.id}&status=WON&${extra}&sort=createdAt,desc`,
    }).then(
      (res) => {
        if (res.data.page.number + 1 < res.data.page.totalPages) {
          setCanLoadMore(true);
        } else {
          setCanLoadMore(false);
        }
        setPageInfo(res.data.page);
        if (fetchingMore) {
          setTransactions([
            ...transactions,
            ...res.data._embedded.gameTransactions,
          ]);
          setFetchingMore(false);
        } else {
          setTransactions(res.data._embedded.gameTransactions);
          setFetching(false);
        }
      },
      (err) => {
        Notification.bubble({
          type: "error",
          content: errorHandler(err),
        });
      }
    );
  };

  return (
    <div className="modalCustom-content-2">
      <h3 style={{ textAlign: "left" }}>Winning Ticket Details</h3>
      <br />
      <div className="trans-table">
        {transactions.map((item, id) => {
          console.log(item);
          return (
            <div>
              <div className="drawItem">
                <div className="link">Ticket</div>
                <div className="focus">{item.gameToken}</div>
              </div>
              <div className="drawItem">
                <div className="link">Draw Time</div>
                <div className="focus">
                  {item.draw &&
                    moment(new Date(item.draw.drawTime)).format(
                      "DD-MM-YYYY hh:mm a"
                    )}
                </div>
              </div>
            </div>
          );
        })}
        <br />
        {!fetching && transactions.length < 1 && (
          <center>
            <strong style={{ color: "white" }}>
              {props.useActive
                ? `Your Ticket "${props.search}" has not won`
                : "No data available"}
            </strong>
          </center>
        )}
        {fetching && (
          <center>
            <Spinner color={secondaryColor} />
          </center>
        )}
        {canLoadMore && (
          <center>
            <Button
              loading={fetchingMore}
              disabled={fetchingMore}
              onClick={loadMore}
            >
              Load more
            </Button>
          </center>
        )}
      </div>
    </div>
  );
};

export default ResultsHistory;
