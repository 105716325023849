import React, { useState } from "react";
import { Button } from "../../components/button/Button";
import ModalAdverts from "../../components/modalAdverts/modalCustom";
import BlackFriday from "./blackFriday";
import CountDown from "./countDown";
import DaysOf from "./daysOf";

function QuickTests() {
  const [modalType, setModalType] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const viewModal = (typeMain) => {
    setModalType(typeMain);
    setShowModal(true);
  };

  return (
    <div>
      <Button onClick={() => viewModal(1)}>Show Black Friday</Button>
      <br />
      <Button onClick={() => viewModal(2)}>Show Countdown</Button>

      <ModalAdverts visible={showModal}>
        {modalType === 1 && <BlackFriday onClose={() => setShowModal(false)} />}
        {modalType === 2 && <CountDown onClose={() => setShowModal(false)} />}
      </ModalAdverts>
    </div>
  );
}

export default QuickTests;
