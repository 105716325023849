import React, { useState, useEffect } from "react";
import MobileShare from "./mobileShare";
import "../../styles/dashboard.css";
import { axiosHandler } from "../../utils/axiosHandler";
import { formatCurrency, getClientId, getToken } from "../../utils/helper";
import { GAME_TRANSACTIONS_URL } from "../../utils/urls";
import moment from "moment";
import { Button } from "../../components/button/Button";
import { Spinner } from "../../components/spinner/Spinner";
import { primaryColor } from "../../utils/data";
import AppIcon from "../../components/icons/Icon";
import RangeFilter from "../../components/rangeFilter/rangeFilter";
import qs from "query-string";

function RecentWinners(props) {
  const [transactions, setTransactions] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [pageInfo, setPageInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    setFetching(true);
    getTransactions();
  }, [currentPage, queryParams]);

  const getTransactions = async () => {
    let params = qs.stringify(queryParams);
    let extra = `page=${currentPage}&size=6&${params
      .replace("startDate", "startTime")
      .replace("endDate", "endTime")}`;
    const res = await axiosHandler({
      method: "get",
      clientID: getClientId(),
      token: getToken(),
      url:
        GAME_TRANSACTIONS_URL +
        `?projection=gameTransactionWithDetails&sort=createdAt,desc&status=WON&size=6&${extra}`,
    });
    if (!res) return;
    setPageInfo(res.data.page);
    setTransactions(res.data._embedded.gameTransactions);
    setFetching(false);
  };
  return (
    <>
      <div className="dashboard-inner2">
        <div className="heading-dash flex align-center justify-between flex-wrap">
          <h3>Recent Winners</h3>&nbsp;&nbsp;&nbsp;
          {/* <RangeFilter
            startDate={queryParams.startDate}
            endDate={queryParams.endDate}
            onChange={(e) =>
              setQueryParams({
                ...queryParams,
                ...e,
              })
            }
          /> */}
        </div>
        <h4 className="mobile">Deposits</h4>
        <div className="trans-table">
          <div className="t-content">
            <table>
              <thead>
                <tr>
                  <th>UserID</th>
                  <th>Game</th>
                  <th>Prize</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {!fetching &&
                  transactions.map((item, id) => {
                    return (
                      <tr key={id}>
                        {item.userId ? (
                          <td>****{item.userId.substr(-8)}</td>
                        ) : (
                          <td></td>
                        )}
                        <td>{item.gameInstance.label}</td>
                        <td>{item.prize.label}</td>
                        <td>{formatCurrency(item.prize.amount)}</td>
                        <td>
                          {moment(new Date(item.createdAt)).format(
                            "YYYY-MM-DD HH:mmA"
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <br />
          {!fetching && transactions.length < 1 && (
            <center>
              <strong style={{ color: "white" }}>No data available</strong>
            </center>
          )}
        </div>
        {!fetching && (
          <div className="pager">
            <span>
              {pageInfo.number + 1} of {pageInfo.totalPages}
            </span>
            <button
              className={currentPage > 0 ? "" : "disabled"}
              onClick={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1);
                }
              }}
            >
              <AppIcon name="chevronLeft" type="feather" />
            </button>
            <button
              className={
                currentPage + 1 < pageInfo.totalPages ? "" : "disabled"
              }
              onClick={() => {
                if (currentPage + 1 < pageInfo.totalPages) {
                  setCurrentPage(currentPage + 1);
                }
              }}
            >
              <AppIcon name="chevronRight" type="feather" />
            </button>
          </div>
        )}
        {fetching && (
          <center>
            <Spinner color={primaryColor} />
          </center>
        )}
      </div>
      <MobileShare />
    </>
  );
}
export default RecentWinners;
