import React, { useState, useEffect, useContext } from "react";
import MobileShare from "./mobileShare";
import "../../styles/dashboard.css";
import { store } from "../../stateManagement/store";
import { axiosHandler } from "../../utils/axiosHandler";
import {
  formatCurrency,
  getClientId,
  getToken,
  numberWithCommas,
} from "../../utils/helper";
import { TRANSACTIONS_URL } from "../../utils/urls";
import moment from "moment";
import { Spinner } from "../../components/spinner/Spinner";
import { primaryColor } from "../../utils/data";
import AppIcon from "../../components/icons/Icon";
import qs from "query-string";
import RangeFilter from "../../components/rangeFilter/rangeFilter";

function CashFlowHistory(props) {
  const [transactions, setTransactions] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageInfo, setPageInfo] = useState(null);
  const [queryParams, setQueryParams] = useState("");
  const {
    state: { userWallet, userDetails },
  } = useContext(store);

  useEffect(() => {
    if (!userDetails.userId) return;
    setFetching(true);
    getTransactions();
  }, [userWallet, currentPage, queryParams, userDetails]);

  const getTransactions = async (extraMain = "") => {
    let params = qs.stringify(queryParams);
    let extra = `page=${currentPage}&size=6&${params}`;
    const res = await axiosHandler({
      method: "get",
      clientID: getClientId(),
      token: getToken(),
      url:
        TRANSACTIONS_URL +
        `?sort=createdAt,desc&${extra}${extraMain}customerId=${userDetails.userId}`,
    });
    if (!res) return;
    setPageInfo(res.data.page);
    if (fetchingMore) {
      setTransactions([...transactions, ...res.data._embedded.transactions]);
      setFetchingMore(false);
    } else {
      setTransactions(res.data._embedded.transactions);
      setFetching(false);
    }
  };

  const filter = (e) => {
    let filterer = "";
    if (e.target.value === "debit") {
      filterer = "creditAmount=0";
    } else if (e.target.value === "credit") {
      filterer = "debitAmount=0";
    }
    setFetching(true);
    getTransactions(filterer);
  };

  return (
    <>
      <div className="dashboard-inner2">
        <div className="heading-dash">
          <h3>Cashflow History</h3>&nbsp;&nbsp;&nbsp;
          <select onChange={filter}>
            <option value="">All</option>
            <option value="debit">Deposits</option>
            <option value="credit">Withdrawals</option>
          </select>
        </div>
        {/* <RangeFilter
          startDate={queryParams.startDate}
          endDate={queryParams.endDate}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              ...e,
            })
          }
        /> */}
        <h4 className="mobile">Deposits</h4>
        <div className="trans-table">
          <div className="t-content">
            <table>
              <thead>
                <tr>
                  <th>Transaction Ref</th>
                  <th>Type</th>
                  <th style={{ minWidth: 100 }}>Date</th>
                  <th>Amount(₦)</th>
                  <th className="desktop">Narration</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((item, id) => {
                  let Ttype = "DR";
                  let amount = item.debitAmount;
                  if (item.creditAmount > 0) {
                    Ttype = "CR";
                    amount = item.creditAmount;
                  }
                  return (
                    <tr key={id}>
                      <td>
                        {item.reference.substring(0, 10)}
                        {item.reference.length > 10 ? "..." : ""}
                      </td>
                      <td className={Ttype === "DR" ? "debit" : "credit"}>
                        {Ttype}
                      </td>
                      <td>
                        {moment(new Date(item.createdAt)).format(
                          "DD-MM-YYYY hh:mm a"
                        )}
                      </td>
                      <td>{formatCurrency(amount / 100)}</td>
                      <td style={{ minWidth: 300 }} className="desktop">
                        {item.narration}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            {!fetching && transactions.length < 1 && (
              <center>
                <strong style={{ color: "white" }}>No data available</strong>
              </center>
            )}
          </div>
          {!fetching && transactions.length > 0 && (
            <div className="pager">
              {console.log(pageInfo)}
              <span>
                {pageInfo.number + 1} of {pageInfo.totalPages}
              </span>
              <button
                className={currentPage > 0 ? "" : "disabled"}
                onClick={() => {
                  if (currentPage > 0) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                <AppIcon name="chevronLeft" type="feather" />
              </button>
              <button
                className={
                  currentPage + 1 < pageInfo.totalPages ? "" : "disabled"
                }
                onClick={() => {
                  if (currentPage + 1 < pageInfo.totalPages) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                <AppIcon name="chevronRight" type="feather" />
              </button>
            </div>
          )}
        </div>
        {fetching && (
          <center>
            <Spinner color={primaryColor} />
          </center>
        )}
      </div>
      <MobileShare />
    </>
  );
}

export default CashFlowHistory;
