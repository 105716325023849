import {
  setGlobalLoader,
  setUserDetails,
  setOnBoarding,
  setInitState,
  setBalanceTrigger,
  showRedirect,
  setUserWallet,
  setGameDataState,
  triggerPaymentComplete,
  setGameInstances,
} from "./actions";

export const userDetailsState = {
  userDetails: {},
};

export const gameInstancesState = {
  gameInstances: [],
};

export const gameDataState = {
  activeGameData: null,
};

export const paymentCompleteState = {
  paymentCompleteTrigger: false,
};

export const userWalletState = {
  userWallet: {},
};

export const initState = {
  initStatus: true,
};

export const showRedirectState = {
  showRedirect: {
    status: false,
  },
};

export const balanceTriggerState = {
  balanceTrigger: true,
};

export const globalLoaderState = {
  globalLoader: {
    status: false,
    content: "",
  },
};

export const onBoardingState = {
  onBoarding: {
    status: false,
    callBack: null,
    signIn: false,
  },
};

export const gameDataReducer = (state, action) => {
  if (action.type === setGameDataState) {
    return {
      ...state,
      activeGameData: action.payload,
    };
  } else {
    return state;
  }
};

export const gameInstanceReducer = (state, action) => {
  if (action.type === setGameInstances) {
    return {
      ...state,
      gameInstances: action.payload,
    };
  } else {
    return state;
  }
};

export const paymentCompleteReducer = (state, action) => {
  if (action.type === triggerPaymentComplete) {
    return {
      ...state,
      paymentCompleteTrigger: action.payload,
    };
  } else {
    return state;
  }
};

export const userDetailReducer = (state, action) => {
  if (action.type === setUserDetails) {
    return {
      ...state,
      userDetails: action.payload,
    };
  } else {
    return state;
  }
};

export const userWalletReducer = (state, action) => {
  if (action.type === setUserWallet) {
    return {
      ...state,
      userWallet: action.payload,
    };
  } else {
    return state;
  }
};

export const globalLoaderReducer = (state, action) => {
  if (action.type === setGlobalLoader) {
    return {
      ...state,
      globalLoader: action.payload,
    };
  } else {
    return state;
  }
};

export const showRedirectReducer = (state, action) => {
  if (action.type === showRedirect) {
    return {
      ...state,
      showRedirect: action.payload,
    };
  } else {
    return state;
  }
};

export const onBoardingReducer = (state, action) => {
  if (action.type === setOnBoarding) {
    return {
      ...state,
      onBoarding: action.payload,
    };
  } else {
    return state;
  }
};

export const initReducer = (state, action) => {
  if (action.type === setInitState) {
    return {
      ...state,
      initStatus: action.payload,
    };
  } else {
    return state;
  }
};

export const setBalanceTriggerReducer = (state, action) => {
  if (action.type === setBalanceTrigger) {
    return {
      ...state,
      balanceTrigger: action.payload,
    };
  } else {
    return state;
  }
};
