import React, { useState, useEffect } from "react";
import "./modalCustom.css";

function ModalAdverts(props) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <div className={`modalCustom ${visible ? "show" : ""}`}>
      {props.children}
    </div>
  );
}

export default ModalAdverts;
